<template>
  <div class="elite-metting">
    <item-title :title="{name: '明亚高峰会', en_1: 'MINGYA', en_2: 'SUMMIT'}" />
    <div class="metting-content">
      <div class="years">
        <div @click="prevHandle" class="prev">
          <img v-if="currentIndex != 0" src="../../assets/img/eliteStyle/prev_active.png" alt="">
          <img v-else src="../../assets/img/eliteStyle/prev.png" alt="">
        </div>
        <div class="years-list-wrapper" ref="year-list-wrapper">
          <div class="years-list" ref="year-list" :style="{transform:'translateX('+translateX+')'}">
            <div ref="year-item" @click="switchHandle(index)" v-for="(item, index) in listData" :key="index" class="year-item">
              <div :class="[index == currentIndex ? 'active' : '', 'round']"></div>
              <div :class="[index == currentIndex ? 'active' : '', 'text']">{{item.year}}年</div>
            </div>
          </div>
        </div>

        <div @click="nextHandle" class="next">
          <img v-if="currentIndex != listData.length - 1" src="../../assets/img/eliteStyle/next_active.png" alt="">
          <img v-else src="../../assets/img/eliteStyle/next.png" alt="">
        </div>
      </div>
      <div v-if="listData.length > 0" class="content">
        <div class="title">{{listData[currentIndex].name}}</div>
        <!-- <div class="detail">关于2018年度「法国&日本」高峰会的相关简介，描述高峰会的召开场景和简单的讲述
        高峰会的精彩过程和明亚保险经纪高峰会拓展活动意义</div> -->
      </div>
      <div v-if="listData.length > 0 && isMobile" class="carouse-container mobile-carouse">
        <el-carousel indicator-position="none" :interval="3000" arrow="always">
          <el-carousel-item v-for="(item, index) in listData[currentIndex].pic" :key="index">
            <img style="width: 100%; height: auto;" :src="item" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-else-if="listData.length > 0 && !isMobile" class="carouse-container pc-carouse">
        <el-carousel :interval="3000" type="card" height="3.4rem">
          <el-carousel-item style="height: 3.4rem; width: 6.04rem;" v-for="(item, index) in listData[currentIndex].pic" :key="index">
            <img style="height: 3.4rem; width: 6.04rem;" :src="item" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
      <div v-show="!isShowMemberList" @click="isShowMemberList = true" class="show-member-btn">点击查看人员名单 +</div>

      <div v-if="isShowMemberList" class="member-list">
        <div class="title">会员名单</div>
        <div class="tips">排名不分先后</div>
        <div v-if="members1.list.length > 0" class="list-box">
          <div class="name">{{members1.name}}</div>
          <div class="list">
            <div v-for="(item, index) in members1.list" :key="index" :class="[(Math.ceil((index+1) / 10) % 2 == 0) ? 'even' : 'odd', 'list-item']">{{item.name}}</div>
          </div>
        </div>
        <div v-if="members2.list.length > 0" class="list-box">
          <div class="name">{{members2.name}}</div>
          <div class="list">
            <div v-for="(item, index) in members2.list" :key="index" :class="[(Math.ceil((index+1) / 10) % 2 == 0) ? 'even' : 'odd', 'list-item']">{{item.name}}</div>
          </div>
        </div>
        <div v-if="members3.list.length > 0" class="list-box">
          <div class="name">{{members3.name}}</div>
          <div class="list">
            <div v-for="(item, index) in members3.list" :key="index" :class="[(Math.ceil((index+1) / 10) % 2 == 0) ? 'even' : 'odd', 'list-item']">{{item.name}}</div>
          </div>
        </div>
        <div class="up">
          <div @click="isShowMemberList = false" class="upbtn">
            <span>收起名单</span>
            <img src="../../assets/img/eliteStyle/uparrow.png" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ItemTitle from '@/components/common/itemTitle'
import { getSummitList, getSummitMember } from '@/api/eliteStyle'
import { isMobile } from '@/helper/utils'
export default {
  components: {
    ItemTitle
  },
  data() {
    return {
      isMobile: isMobile(),
      isShowMemberList: false,
      listData: [],
      currentIndex: 0,
      members1: {
        name: '特级荣誉会员',
        list: []
      },
      members2: {
        name: '高级荣誉会员',
        list: []
      },
      members3: {
        name: '荣誉会员',
        list: []
      },
      translateX: '0px',
      timer: null,
      timer1: null,
      timer_num: 0
    }
  },
  created() {
    this.getData()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    clearInterval(this.timer1)
  },
  mounted () {
    let _this = this
    this.$nextTick(() => {
      this.timer = setInterval(function () {        
        if (document.querySelector('.el-carousel__container')) {
          clearInterval(_this.timer)
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
      }, 200)
      window.onresize = function () {
        if (document.querySelector('.el-carousel__container')) {
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
      }      
    })
  },
  watch:{
    $route(to, from){
      if (from.name != 'eliteDetail' && to.name == 'meeting') {
        this.getData()
      }      
    }
  },
  methods: {
    // 获取会员列表
    async getMemberList() {
      const res = await getSummitMember({ sid: this.listData[this.currentIndex].year })
      let list1 = []
      let list2 = []
      let list3 = []
      res.list.forEach(item => {
        if (item.morder == 1) {
          list1.push(item)
        } else if (item.morder == 2) {
          list2.push(item)
        } else if (item.morder == 3) {
          list3.push(item)
        }
      })
      this.members1.list = list1
      this.members2.list = list2
      this.members3.list = list3
      let _this = this
      clearInterval(this.timer1)
      this.$nextTick(() => {
        this.timer1 = setInterval(function () {
        this.timer_num = this.timer_num + 1
        if (document.querySelector('.el-carousel__container')) {
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          if (isMobile()) {
            var style = document.createElement('style');
            style.type = 'text/css';
            style.innerHTML=".el-carousel__container{ height: "+height+"px!important; }";
            document.getElementsByTagName('HEAD').item(0).appendChild(style);
          }
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
      }, 100)
      })
      window.onresize = function () {
        if (document.querySelector('.el-carousel__container')) {
          let height = document.querySelector('.el-carousel__container .el-carousel__item').offsetWidth / (16/9)
          // console.log(height)
          document.querySelector('.el-carousel__container').style.height = height + 'px'
        }
      }
      setTimeout(function() {
        clearInterval(_this.timer1)
      }, 500)
    },
    switchHandle(index) {
      this.currentIndex = index
      this.$forceUpdate()
      this.getMemberList()

      this.countTranslateX()
    },
    prevHandle() {
      if (this.currentIndex > 0) {
        this.currentIndex = this.currentIndex - 1
        // this.$forceUpdate()
        this.getMemberList()
      }

      this.countTranslateX()
    },
    nextHandle() {
      if (this.currentIndex < this.listData.length - 1) {
        this.currentIndex = this.currentIndex + 1
        // this.$forceUpdate()
        this.getMemberList()
      }

      this.countTranslateX()
    },
    async getData() {
      const res = await getSummitList({})
      this.listData = res.list
      
      this.$emit('loading', false)
      this.getMemberList()
    },
    countTranslateX() {
      if (isMobile()) {
        this.$nextTick(() => {
          let $container = this.$refs['year-list-wrapper']
          //let $list = this.$refs['year-list']
          let $item = (this.$refs['year-item'] || [])[0]
          let containerWidth = $container.offsetWidth;
          //let listWidth = $list.offsetWidth;
          let itemWidth = $item.offsetWidth;
          let dis = (this.currentIndex + 1) * itemWidth - containerWidth
          if (dis > 0) {
            //隐藏
            this.translateX = -dis + 'px'
          } else {
            this.translateX = 0
          }
          //console.log(itemWidth, this.translateX)
        })
      }

    }
  }
}
</script>
<style>
.el-carousel__indicator.el-carousel__indicator--horizontal.is-active
  .el-carousel__button {
  background-color: #fe6917;
}
button.el-carousel__arrow {
  outline: none !important;
}
.el-carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0; */
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
<style lang="scss" scoped>
.member-list {
  .up {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0.6rem;
    border-radius: 0 0 0.08rem 0.08rem;
    box-shadow: 0px 10px 15px 0px rgba(169, 169, 169, 0.05);
    .upbtn {
      display: flex;
      align-items: center;
      font-size: 0.16rem;
      color: #fe6917;
      cursor: pointer;
      img {
        width: 0.12rem;
        height: 0.07rem;
        margin-left: 0.12rem;
      }
    }
  }
  .tips {
    font-size: 0.16rem;
    color: #cccccc;
    text-align: right;
  }
  .list-box {
    font-size: 0.18rem;
    .name {
      color: #fe6917;
      margin-top: 0.2rem;
      font-weight: bold;
      padding-left: 0.25rem;
    }
    .list {
      margin-top: 0.2rem;
      display: flex;
      flex-wrap: wrap;
      border-left: 1px solid #eeeeee;
      border-top: 1px solid #eeeeee;
      .list-item {
        width: 10%;
        box-sizing: border-box;
        border-right: 1px solid #eeeeee;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 0.46rem;
        &.odd {
          background-color: #fff8ec;
        }
      }
    }
  }
  .title {
    width: 1.3rem;
    font-weight: bold;
    font-size: 0.24rem;
    margin: 0 auto;
    position: relative;
    text-align: center;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 0.16rem;
      border-radius: 0.08rem;
      background-color: #ffdbc7;
      position: absolute;
      left: 0;
      bottom: -0rem;
      z-index: -1;
    }
  }
}
.metting-content {
  padding-top: 0.8rem;
  .show-member-btn {
    margin: 1rem auto 0;
    width: 2.4rem;
    height: 0.48rem;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    border-radius: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.18rem;
    color: #666666;
    cursor: pointer;
    transition: all 0.5s;
    &:hover {
      background-color: #fe6917;
      color: #ffffff;
      border-color: #fe6917;
    }
  }
  .carouse-container {
    margin-top: 0.6rem;
  }
  .content {
    margin-top: 1rem;
    .title {
      color: #fe6917;
      font-size: 0.4rem;
      font-weight: bold;
      text-align: center;
    }
    .detail {
      font-size: 0.16rem;
      color: #666666;
      line-height: 0.24rem;
      text-align: center;
      width: 56%;
      margin: 0.2rem auto 0;
    }
  }
  .years {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .next,
    .prev {
      width: 0.22rem;
      height: 0.18rem;
      display: flex;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
        vertical-align: middle;
      }
    }
    .years-list-wrapper {
      flex: 1;
    }
    .years-list {
      box-sizing: border-box;
      padding: 0 0.3rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      .year-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        &::after {
          display: block;
          content: "";
          width: 120%;
          height: 0.02rem;
          background-color: #e6e6e6;
          position: absolute;
          top: 0.1rem;
          right: -0.49rem;
        }
        &:last-child::after {
          display: none;
        }
        .text {
          font-weight: bold;
          color: #666666;
          &.active {
            color: #fe6917;
          }
        }
        .round {
          width: 0.18rem;
          height: 0.18rem;
          background-color: #e6e6e6;
          border-radius: 50%;
          margin-bottom: 0.15rem;
          position: relative;
          z-index: 100;
          &.active {
            width: 0.21rem;
            height: 0.21rem;
            border-radius: 50%;
            border: 0.06rem solid #fe6917;
            animation: shine 1s ease infinite;
          }
        }
      }
    }
  }
}
@keyframes shine {
  0% {
    box-shadow: 0 0 0px 0px rgba(241, 105, 31, 0.9);
  }
  100% {
    box-shadow: 0 0 0px 0.1rem rgba(245, 10, 213, 0);
  }
}
@media only screen and (max-width: $moble_width) {
  .metting-content {
    .years {
      .next,
      .prev {
        flex-shrink: 0;
      }
      .years-list-wrapper {
        max-width: calc(100% - 0.44rem);
        margin-top: -20px;
        overflow: hidden;
      }
      .years-list {
        padding: 0;
        padding-top: 20px;
        .year-item {
          .text {
            font-size: 12px;
            text-align: center;
            max-width: 120%;
            min-width: 48px;
            white-space: nowrap;
            font-weight: normal;
            //padding: 0 2px;
          }
        }
      }
    }
    .show-member-btn {
      margin-top: 0.8rem;
    }
    .content {
      margin-top: .5rem;
    }
    .carouse-container {
      margin-top: .75rem;
    }
  }
  
  .member-list {
    margin-top: 0.5rem;
    .tips {
      text-align: left;
      padding-left: 0.25rem;
    }
    .list-box {
      .list {
        .list-item {
          width: 20%;
        }
      }
    }
  }
}
</style>